@import 'utils-module';

.b-partners {
  position: relative;
  min-height: 200px;
  // padding-top: 5rem;
  background-color: $white;
  padding-top: 100px;
  // padding-top: 180px;

  @media screen and (width <= 1023px) {
    // padding-top: 90px;
    padding-top: 30px;
  }

  // @media (width <= 1440px) {
  //   padding-top: 2.2rem;
  // }

  // @media (width <= 1024px) {
  //   padding-top: 3.8rem;
  // }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.625rem;

    @media screen and (width <= 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (width <= 500px) {
      grid-template-columns: repeat(2, 1fr);
    }

    &_center {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__partner {
    text-align: center;
    background: $white;
    border-radius: 10px;
  }

  &__img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 7.5rem;
    height: 7.5rem;
    margin: 0 auto;

    background-color: $white;
    border-radius: 6.25rem;

    @media (width <= 1024px) {
      width: 6.25rem;
      height: 6.25rem;
    }
  }

  &__big-img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 26.25rem;
    height: 13.75rem;
    margin: 0 auto;

    background-color: $white;
    border-radius: 6.25rem;

    @media (width <= 1024px) {
      width: 13.75rem;
      height: 13.75rem;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__status {
    width: 65%;
    margin: 0.7rem auto;
    font-size: 21px;
    color: $black;
    font-family: $pioner-sans;

    @media (width <= 1679px) {
      font-size: 1rem;
    }

    @media (width <= 768px) {
      width: 85%;
      font-size: 0.75rem;
    }
  }
}
