@mixin transition($property: all, $speed: 0.3s, $easing: ease-in) {
  transition: $property $speed $easing;
}

@mixin button($background-color, $color) {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 12.5rem;
  padding: 0.625rem 1.875rem;

  font-family: $montserrat;
  font-size: 1rem;
  font-weight: 700;
  color: $color;
  text-transform: uppercase;

  background-color: $background-color;
  border: none;
  border-radius: 0.625rem;
  outline: none;

  @include transition;
}

@mixin button-hover($background-color, $color) {
  color: $color !important;
  text-shadow: 0 0 0.9px $color, 0 0 0.9px $color, 0 0 0.9px $color;
  background-color: $background-color !important;

  @include transition;
}

@mixin hover() {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin hide-scrollbar() {
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
