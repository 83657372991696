*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  margin: 0 !important;
  font-family: $pioner-sans !important;
  background-color: $black-dark;
}

html {
  min-height: 100%;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

ol {
  list-style-position: inside;
}

.b-container {
  position: relative;

  margin: 0 auto;
  padding: 0 3.75rem;

  @media (width <= 1679px) {
    padding: 0 2.8rem;
  }

  @media (width <= 768px) {
    padding: 0 1.875rem;
  }
}

.b-container_alt {
  padding: 0 2.8125rem;

  @media (width <= 1679px) {
    padding: 0 2.625rem;
  }

  @media (width <= 768px) {
    padding: 0 0.9375rem;
  }
}

.section-title {
  font-size: 100px;
  font-weight: bold;
  font-family: $abraxas;
  line-height: 100%;
  text-transform: uppercase;
  color: $orange;
  margin-bottom: 3.2rem;

  br {
    display: none;

    @media screen and (width <= 768px) {
      display: block;
    }
  }

  &_partner {
    text-align: center;
    font-size: 60px !important;
    color: $black;
    margin: 6.3rem 0 4rem;

    @media screen and (width <= 1440px) {
      font-size: 50px !important;
      margin: 5rem 0 2.5rem !important;
    }

    @media screen and (width <= 768px) {
      font-size: 25px !important;
      line-height: 124%;
      margin: 3.8rem 0 1.9rem !important;
    }
  }

  @media screen and (width <= 1679px) {
    font-size: 80px;
    margin-bottom: 2.5rem;
  }

  @media screen and (width <= 768px) {
    font-size: 35px;
    margin-bottom: 1.9rem;
  }
}
