// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bestchefs-v3-primary: mat.define-palette(mat.$indigo-palette);
$bestchefs-v3-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$bestchefs-v3-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bestchefs-v3-theme: mat.define-light-theme(
  (
    color: (
      primary: $bestchefs-v3-primary,
      accent: $bestchefs-v3-accent,
      warn: $bestchefs-v3-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bestchefs-v3-theme);

/* You can add global styles to this file, and also import other style files */
@import 'styles/app';

.app-view {
  flex: 1;
  margin-top: 226px;

  @media screen and (width <= 1440px) {
    margin-top: 174px;
  }

  @media screen and (width <= 1023px) {
    margin-top: 90px;
  }
}

.app-header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  width: 100%;
}

.b-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
