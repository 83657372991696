.cdk-global-overlay-wrapper {
  pointer-events: auto;
}

.backdropBackground,
.ks-modal-gallery-backdrop {
  background: rgb(0 0 0 / 0.5);
}

.news-background {
  background: rgb(0 0 0 / 0.85);

  & + .cdk-global-overlay-wrapper {
    @include hide-scrollbar();
    align-items: flex-start !important;
  }
}

.news-dialog {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: none !important;

  .mat-mdc-dialog-container {
    padding-top: 300px;
    padding-bottom: 50px;

    @media screen and (width < 1680px) {
      padding-top: 280px;
    }

    @media screen and (width < 1440px) {
      width: calc(100% - 85px * 2);
      padding: 265px 0 0;
    }

    @media screen and (width <= 1024px) {
      padding-top: 205px;
    }

    @media screen and (width < 768px) {
      padding-top: 163px;
    }

    @media screen and (width < 600px) {
      width: calc(100% - 20px * 2);

      padding: 175px 0 0;
    }
  }

  .mdc-dialog__surface {
    background-color: transparent !important;
  }
}

/* Styles for VideoPopupComponent */
.fullscreen-dialog {
  mat-dialog-container {
    overflow: hidden;

    width: 52vw;
    height: 65vh;
    padding: 10px;

    background: none;
    box-shadow: none;
  }

  mat-dialog-content {
    position: relative;
    overflow: hidden;
    height: 100%;
    padding: 24px;
  }
}

/* Styles for MainThemePopupComponent */
.popup-shell {
  max-width: 50vw !important;
  font-family: $montserrat;
  color: $black;

  ol {
    counter-reset: list;
    list-style: none;
    list-style-position: inside;

    li::before {
      content: counter(list) ') ';
      counter-increment: list;
    }
  }

  &__icon-close {
    width: 20px !important;
    height: 20px !important;
  }

  &__container {
    position: relative;
    padding: 0 10px;
    font-size: 18px;
  }

  .popup-text {
    margin-bottom: 5px;

    .bold {
      font-weight: 700;
    }
  }

  .popup-list {
    margin-bottom: 5px;
  }

  .popup-list-item {
    margin-bottom: 5px;
  }

  &__button-close {
    cursor: pointer;

    display: flex;

    color: $black;

    background: none;
    border: none;
    outline: none;

    @include transition(transform);

    &:hover {
      transform: rotate(90deg);
    }
  }

  mat-dialog-container {
    position: relative;
  }

  mat-dialog-content {
    max-height: 80vh;
  }

  mat-dialog-actions {
    position: absolute;
    top: 0;
    right: 1%;
    min-height: auto;
  }
}

/* Styles for AvatarPopupComponent */
.avatar-popup {
  mat-dialog-container {
    padding: 0 24px;
    color: $white;
    background-color: $black;
    border-radius: 20px;
  }

  mat-dialog-content {
    width: 80vw;
    max-width: 650px;
    max-height: 100vh;
    padding: 0;
  }
}

/* Styles for XmasPopupComponent */
.xmas-popup {
  min-width: 1000px;
  max-width: 1000px;

  .mat-dialog-container {
    padding: 0;
  }

  .mat-dialog-content {
    min-height: 560px;
    margin: 0;
    padding: 0;
  }

  @media screen and (width <= 1023px) {
    min-width: 750px;

    .mat-dialog-content {
      min-height: 430px;
    }
  }

  @media screen and (width <= 767px) {
    min-width: 350px;

    .mat-dialog-content {
      min-height: 680px;
      max-height: 680px;
    }
  }

  @media screen and (width <= 500px) {
    min-width: 300px;

    .mat-dialog-content {
      min-height: 560px;
      max-height: 560px;
    }
  }
}

@media (width <= 1440px) {
  .fullscreen-dialog {
    mat-dialog-container {
      width: 62vw;
    }
  }
}

@media (width <= 1024px) {
  .fullscreen-dialog {
    max-width: 100vw !important;

    mat-dialog-container {
      width: 100vw;
    }
  }

  .popup-shell {
    max-width: 80vw !important;
  }
}

@media (width <= 768px) {
  .fullscreen-dialog {
    mat-dialog-container {
      height: 50vh;
    }
  }

  .backdropBackground {
    background: rgb(0 0 0 / 0.7);
  }
}

@media (width <= 500px) {
  .fullscreen-dialog {
    mat-dialog-container {
      height: 45vh;
    }
  }

  .popup-shell {
    max-width: 90vw !important;

    mat-dialog-actions {
      right: 3%;
    }
  }
}

/* Styles for Ks Modal Gallery */
.dots-container,
.inside.description {
  display: none !important;
}

.adult-panel {
  min-width: 50vw !important;
  min-height: 50vh;

  .mat-mdc-dialog-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-mdc-dialog-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 22px !important;
    text-align: center;
  }

  .buttons {
    justify-content: center;
    margin-top: 30px;
  }

  .mat-mdc-button {
    &:last-child {
      margin-left: 25px !important;
    }

    &:first-child {
      background-color: $tooltip;
    }
  }

  @media screen and (width <= 1280px) {
    min-width: 65vw;
  }

  @media screen and (width <= 1024px) {
    min-width: 75vw;
  }

  @media screen and (width <= 768px) {
    min-width: 90vw;
  }
}
