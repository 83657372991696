$main-color: #ea0000;
$main-red: #ea0000;
$main-bg-color: #f51a1f;
$green: #e0f95c;
$yellow: #ffcc0d;
$black: #000;
$black-dark: #252525;
$white: #fff;
$text-color: #3e3b39;
$gray: #808080;
$price-disabled: #bdbdbe;
$lightgray: #f8f8f8;
$divider: #929292;
$tooltip: #e6eaed;
$button-disabled: rgb(255 255 255 / 0.2);
$montserrat: 'Montserrat', sans-serif;
$akony: 'Akony', sans-serif;

$pioner-sans: 'Pioner Sans Regular', sans-serif;
$pioner-sans-medium: 'Pioner Sans Medium', sans-serif;
$pioner-sans-light: 'Pioner Sans Light', sans-serif;
$abraxas: 'Abraxas', sans-serif;
$orange: #d54e26;
