@font-face {
  font-family: Montserrat;
  font-weight: bold;
  font-style: normal;
  src: url("/assets/fonts/Montserrat/Montserrat-Bold.eot");
  src: url("/assets/fonts/Montserrat/Montserrat-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"),
    url("/assets/fonts/Montserrat/Montserrat-Bold.woff") format("woff"),
    url("/assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
  src: url("/assets/fonts/Montserrat/Montserrat-Regular.eot");
  src: url("/assets/fonts/Montserrat/Montserrat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat/Montserrat-Regular.woff2") format("woff2"),
    url("/assets/fonts/Montserrat/Montserrat-Regular.woff") format("woff"),
    url("/assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 300;
  font-style: normal;
  src: url("/assets/fonts/Montserrat/Montserrat-Light.eot");
  src: url("/assets/fonts/Montserrat/Montserrat-Light.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat/Montserrat-Light.woff2") format("woff2"),
    url("/assets/fonts/Montserrat/Montserrat-Light.woff") format("woff"),
    url("/assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  font-style: normal;
  src: url("/assets/fonts/Montserrat/Montserrat-SemiBold.eot");
  src: url("/assets/fonts/Montserrat/Montserrat-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat/Montserrat-SemiBold.woff2") format("woff2"),
    url("/assets/fonts/Montserrat/Montserrat-SemiBold.woff") format("woff"),
    url("/assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: Rubik;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("/assets/fonts/Rubik/Rubik-Bold.eot");
  src: url("/assets/fonts/Rubik/Rubik-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Rubik/Rubik-Bold.woff2") format("woff2"),
    url("/assets/fonts/Rubik/Rubik-Bold.woff") format("woff"),
    url("/assets/fonts/Rubik/Rubik-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Bebas Neue Pro";
  font-weight: bold;
  font-style: normal;
  src: url("/assets/fonts/Bebas/BebasNeuePro-Bold.eot");
  src: local("Bebas Neue Pro Bold"), local("BebasNeuePro-Bold"),
    url("/assets/fonts/Bebas/BebasNeuePro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Bebas/BebasNeuePro-Bold.woff2") format("woff2"),
    url("/assets/fonts/Bebas/BebasNeuePro-Bold.woff") format("woff"),
    url("/assets/fonts/Bebas/BebasNeuePro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Akony";
  font-weight: bold;
  src: url("/assets/fonts/Akony/Akony.ttf") format("truetype");
}

@font-face {
  font-family: 'Abraxas';
  src: url('/assets/fonts/Abraxas/Abraxas.woff2') format('woff2'),
      url('/assets/fonts/Abraxas/Abraxas.woff') format('woff'),
      url('/assets/fonts/Abraxas/Abraxas.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pioner Sans Light Italic';
  src: url('/assets/fonts/PionerSans/PionerSans-LightItalic.woff2') format('woff2'),
      url('/assets/fonts/PionerSans/PionerSans-LightItalic.woff') format('woff'),
      url('/assets/fonts/PionerSans/PionerSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Pioner Sans Medium Italic';
  src: url('/assets/fonts/PionerSans/PionerSans-MediumItalic.woff2') format('woff2'),
      url('/assets/fonts/PionerSans/PionerSans-MediumItalic.woff') format('woff'),
      url('/assets/fonts/PionerSans/PionerSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Pioner Sans SemiBold';
  src: url('/assets/fonts/PionerSans/PionerSans-SemiBold.woff2') format('woff2'),
      url('/assets/fonts/PionerSans/PionerSans-SemiBold.woff') format('woff'),
      url('/assets/fonts/PionerSans/PionerSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pioner Sans Italic';
  src: url('/assets/fonts/PionerSans/PionerSans-Italic.woff2') format('woff2'),
      url('/assets/fonts/PionerSans/PionerSans-Italic.woff') format('woff'),
      url('/assets/fonts/PionerSans/PionerSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Pioner Sans Bold';
  src: url('/assets/fonts/PionerSans/PionerSans-Bold.woff2') format('woff2'),
      url('/assets/fonts/PionerSans/PionerSans-Bold.woff') format('woff'),
      url('/assets/fonts/PionerSans/PionerSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pioner Sans Regular';
  src: url('/assets/fonts/PionerSans/PionerSans-Regular.woff2') format('woff2'),
      url('/assets/fonts/PionerSans/PionerSans-Regular.woff') format('woff'),
      url('/assets/fonts/PionerSans/PionerSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pioner Sans Light';
  src: url('/assets/fonts/PionerSans/PionerSans-Light.woff2') format('woff2'),
      url('/assets/fonts/PionerSans/PionerSans-Light.woff') format('woff'),
      url('/assets/fonts/PionerSans/PionerSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pioner Sans SemiBold Italic';
  src: url('/assets/fonts/PionerSans/PionerSans-SemiBoldItalic.woff2') format('woff2'),
      url('/assets/fonts/PionerSans/PionerSans-SemiBoldItalic.woff') format('woff'),
      url('/assets/fonts/PionerSans/PionerSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Pioner Sans Medium';
  src: url('/assets/fonts/PionerSans/PionerSans-Medium.woff2') format('woff2'),
      url('/assets/fonts/PionerSans/PionerSans-Medium.woff') format('woff'),
      url('/assets/fonts/PionerSans/PionerSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pioner Sans Bold Italic';
  src: url('/assets/fonts/PionerSans/PionerSans-BoldItalic.woff2') format('woff2'),
      url('/assets/fonts/PionerSans/PionerSans-BoldItalic.woff') format('woff'),
      url('/assets/fonts/PionerSans/PionerSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
