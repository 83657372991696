/* ----- AWARD FORMS ----- */
.mat-option {
  font-family: $montserrat;
}

.mat-option-text {
  color: $black;
}

.award-container {
  margin: 30px auto 0;
  font-size: 18px;
  background-color: $white;
  border-radius: 10px;

  .award-inner-container {
    padding: 0 30px;
  }

  .worksheet-container {
    margin: 0 15px;
    padding: 30px 20px 10px;
    background-color: $lightgray;
    border-radius: 10px;
  }

  .success-form-container {
    padding: 0 30px 15px;
  }

  .mat-form-field {
    display: block;
    margin-bottom: 10px;
    font-family: $montserrat;
  }

  .mat-select,
  .mat-checkbox {
    font-family: $montserrat;
  }

  .mat-checkbox-layout {
    white-space: normal;
  }

  .checkbox-container {
    margin: 30px 0 15px;
  }

  .radio-buttons {
    min-height: 95px;

    label {
      display: block;
      margin-bottom: 10px;
    }

    .mat-error {
      padding: 0 1em;
      font-size: 75%;
    }

    .mat-radio-button {
      font-family: $montserrat;
    }

    .mat-radio-button:first-child {
      margin-right: 30px;
    }
  }

  .mat-placeholder-required,
  .mat-form-field-required-marker {
    color: $main-color;
  }

  .form-paragraph {
    margin-bottom: 20px;
  }

  .form-list {
    margin-bottom: 20px;
  }

  ul.form-list {
    list-style-position: inside;
    list-style-type: disc;
  }

  .form-list-item {
    margin-bottom: 10px;
  }

  .form-paragraph-large-margin {
    margin: 15px 0;
    text-align: center;
  }

  .form-link {
    color: $black;
    text-decoration: underline;

    @include transition;

    &:hover {
      color: $main-color;
    }
  }

  // главная потверждающая кнопка
  .button {
    width: auto;
    margin: 20px auto;
    padding: 15px 30px;

    @include button($main-color, $white);
  }

  .button-link {
    max-width: 40%;
    margin-top: 45px;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: $black;
  }

  .mat-divider {
    margin-bottom: 25px;
  }

  .send-error {
    margin: -10px 0 20px;
  }

  .agreement-link {
    font-size: 16px;
    color: $black;
    text-decoration: underline;
  }

  .asterisk {
    color: $main-color;
  }

  @media screen and (width <= 1440px) {
    .award-form-button {
      width: 200px;
    }
  }

  @media screen and (width <= 1023px) {
    .award-form-button {
      width: 180px;
    }
  }

  @media screen and (width <= 500px) {
    font-size: 16px;

    .award-inner-container {
      padding: 0 15px;
    }

    .button {
      min-width: 170px;
      padding: 15px;
      font-size: 13px;
    }

    .button-link {
      margin-top: 35px;
      font-size: 16px;
    }

    .agreement-link {
      font-size: 14px;
    }
  }

  @media screen and (width <= 360px) {
    .button {
      font-size: 11px;
    }
  }
}

// вспомогательная кнопка
.award-form-button {
  width: 240px;
  padding: 15px 30px;

  @include button($black, $white);
  @include transition;

  &:hover {
    background-color: $main-color;
  }

  @media screen and (width <= 500px) {
    order: 1;
    width: 45%;
    margin-bottom: 20px;
  }
}
